
require('./styles/login.scss');

$(() => {

// select first input
	let form = $('form:first');
	if (form) {
		form.find('input:not([type="hidden"]):first').focus().select();
	}

});
